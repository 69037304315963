<script lang="ts" setup>
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid';
import { scoutApi } from '@libero/api-client/scout/api';
import SearchItem from '@libero/cityview/modules/search/components/SearchItem/SearchItem.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Icon from '@libero/ui-framework/Icon/Icon.vue';
import Key from '@libero/ui-framework/Key/Key.vue';
import Spinner from '@libero/ui-framework/Spinner/Spinner.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { getResourceName, getResourceShowRoute } from '@libero/utilities/resource';
import { useQuery } from '@tanstack/vue-query';
import { onUnmounted } from 'vue';
import { watch } from 'vue';
import { onMounted } from 'vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

interface Props {
  onClose: () => void;
}

const props = defineProps<Props>();

const { t } = useI18n();
const router = useRouter();

const activeIndex = ref(-1);
const input = ref<HTMLElement>();
const searchTerm = ref('');

const { data: searchItems, isFetching } = useQuery({
  queryKey: ['scout.index', searchTerm],
  queryFn: () => {
    if (!searchTerm.value) return Promise.resolve([]);
    return scoutApi.index(searchTerm.value);
  },
});

const handleChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  searchTerm.value = target.value;
};

const openActiveItem = () => {
  const activeItem = searchItems.value?.[activeIndex.value];
  if (!activeItem) return;

  const route = getResourceShowRoute(
    getResourceName(activeItem.resource_type),
    activeItem.resource,
  );

  if (!route) return;

  props.onClose();
  router.push(route);
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (!searchItems.value) return;

  switch (event.key) {
    case 'Enter':
      openActiveItem();
      break;
    case 'ArrowDown':
      if (activeIndex.value < searchItems.value.length - 1) activeIndex.value++;
      break;
    case 'ArrowUp':
      if (activeIndex.value > -1) activeIndex.value--;
      break;
    default:
      return;
  }
};

watch(searchItems, () => {
  activeIndex.value = -1;
});

onMounted(() => {
  document.addEventListener('keydown', handleKeyDown);
  setTimeout(() => input.value?.focus(), 100);
});

onUnmounted(() => {
  document.removeEventListener('keydown', handleKeyDown);
});

const instructions = [
  { keys: ['/'], description: 'search.open' },
  { keys: ['↑', '↓'], description: 'search.navigate' },
  { keys: ['enter'], description: 'open' },
  { keys: ['esc'], description: 'search.close' },
];
</script>

<template>
  <div class="search-input">
    <Cluster alignItems="center" :gap="0.75">
      <Icon size="xl" class="search-input-icon">
        <MagnifyingGlassIcon />
      </Icon>

      <input
        id="search"
        ref="input"
        name="search"
        type="text"
        :placeholder="t('search.placeholder')"
        :value="searchTerm"
        autocomplete="off"
        :onInput="handleChange"
      />
    </Cluster>

    <SearchItem
      v-for="(item, index) in searchItems"
      :key="item.resource.id"
      :item="item"
      :isActive="activeIndex === index"
      :onClick="onClose"
    />

    <div v-if="searchTerm && !searchItems?.length" class="search-input-no-results">
      <Cluster v-if="isFetching" alignItems="center">
        <Spinner />
        <Typography>{{ t('loading') }}</Typography>
      </Cluster>

      <Typography v-else>
        {{ t('pagination.no-results-found') }}
      </Typography>
    </div>

    <Cluster class="search-input-instructions" :gap="0.75">
      <Cluster
        v-for="instruction in instructions"
        :key="instruction.description"
        :gap="0.25"
        alignItems="center"
      >
        <Cluster :gap="0.125">
          <Key v-for="key in instruction.keys" :key="key">
            {{ key }}
          </Key>
        </Cluster>

        <Typography size="sm">{{ t(instruction.description) }}</Typography>
      </Cluster>
    </Cluster>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/cityview/modules/search/components/SearchInput/SearchInput.scss';
</style>
