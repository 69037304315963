<script setup lang="ts">
import { Dropdown, type Placement } from 'floating-vue';
import { ref, watch } from 'vue';

interface Props {
  placement?: Placement;
  isAlwaysOpen?: boolean;
  isPersistent?: boolean;
  shouldStayOpen?: boolean;
  destroyOnClose?: boolean;
  onUpdate?: (open: boolean) => void;
  onClose?: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  placement: 'bottom-end',
  onUpdate: undefined,
  onClose: undefined,
});

const isOpen = ref(false);

const handleUpdateVisibility = (value: boolean) => {
  isOpen.value = value;
};

const handleClickMenu = () => {
  if (!props.shouldStayOpen) {
    isOpen.value = false;

    if (props.isAlwaysOpen) {
      props.onClose?.();
    }
  }
};

watch(isOpen, (value) => {
  props.onUpdate?.(value);
  if (!value) props.onClose?.();
});
</script>

<template>
  <Dropdown
    class="dropdown"
    :shown="isAlwaysOpen || isOpen"
    :triggers="['click']"
    :distance="4"
    :placement="placement"
    :autoHide="!isPersistent"
    eagerMount
    :onUpdate:shown="handleUpdateVisibility"
  >
    <slot v-if="!isAlwaysOpen" name="trigger" :class="['dropdown-trigger', isOpen && 'is-open']" />
    <span v-else />

    <template #popper>
      <div class="dropdown-backdrop" />

      <div :onClick="handleClickMenu">
        <slot v-if="!destroyOnClose || isAlwaysOpen || isOpen" name="content" />
      </div>
    </template>
  </Dropdown>
</template>

<style lang="scss">
@import '@libero/ui-framework/Dropdown/Dropdown.scss';
</style>
