import { api, jsonApi } from '@libero/utilities/api-client';
import { ApiOptions } from '@libero/utilities/api-client';

import type { Media } from './types';

const media = jsonApi('api/media');

const store = (data: FormData, options: ApiOptions): Promise<Media> => {
  return api.post('api/media/uploads', { ...options, body: data, timeout: false }).json();
};

const destroy = (id: string | number): Promise<unknown> => {
  return media.delete(id.toString());
};

export const mediaApi = {
  name: 'media',
  store,
  destroy,
};
