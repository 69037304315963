<script setup lang="ts">
import { ArrowPathIcon, EllipsisVerticalIcon, TrashIcon } from '@heroicons/vue/24/solid';
import type { Resource } from '@libero/api-client/types/resource';
import type { ResourceApi } from '@libero/api-client/types/resource-api';
import { useUserStore } from '@libero/hooks/store/useUserStore';
import DestroyModal from '@libero/organisms/DestroyModal/DestroyModal.vue';
import Dropdown from '@libero/ui-framework/Dropdown/Dropdown.vue';
import DropdownAction from '@libero/ui-framework/Dropdown/DropdownAction.vue';
import DropdownBody from '@libero/ui-framework/Dropdown/DropdownBody.vue';
import DropdownFooter from '@libero/ui-framework/Dropdown/DropdownFooter.vue';
import DropdownHeader from '@libero/ui-framework/Dropdown/DropdownHeader.vue';
import IconButton from '@libero/ui-framework/IconButton/IconButton.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/vue-query';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  title?: string;
  subject?: string;
  item: Pick<Resource, 'id' | 'deleted_at'>;
  resourceApi: Pick<ResourceApi, 'name' | 'permissionBase'> &
    Partial<Pick<ResourceApi, 'destroy' | 'restore' | 'replicate'>>;
  deleteInvalidateKeys?: QueryKey[];
  isAlwaysOpen?: boolean;
  hasNoDelete?: boolean;
  resourceTranslation?: string;
  onDisconnect?: (id: string | number) => void;
}

const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  subject: undefined,
  deleteInvalidateKeys: undefined,
  resourceTranslation: undefined,
  onDisconnect: undefined,
});

const { t } = useI18n();
const queryClient = useQueryClient();
const { hasPermission } = useUserStore();

const { mutate: handleRestore } = useMutation({
  mutationFn: () => {
    if (!props.resourceApi.restore) return Promise.resolve(null);
    return props.resourceApi.restore?.(props.item.id);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: [`${props.resourceApi.name}.index`] });
    queryClient.invalidateQueries({
      queryKey: [`${props.resourceApi.name}.activity`, props.item.id],
    });
  },
});

const permissionBase = computed(() => props.resourceApi.permissionBase || props.resourceApi.name);

const showFooter = computed(
  () =>
    (hasPermission(`${permissionBase.value}.destroy`) && !props.hasNoDelete) ||
    (hasPermission(`${permissionBase.value}.scout`) && props.onDisconnect),
);
</script>

<template>
  <Dropdown :isAlwaysOpen="isAlwaysOpen">
    <template #trigger>
      <span @click.stop>
        <IconButton size="sm" appearance="link" color="secondary">
          <EllipsisVerticalIcon />
        </IconButton>
      </span>
    </template>

    <template #content>
      <template v-if="!item.deleted_at">
        <DropdownHeader v-if="title">
          <Typography tag="h5" type="heading" size="xxs" bold>
            {{ title }}
          </Typography>
        </DropdownHeader>

        <DropdownBody>
          <slot />
        </DropdownBody>

        <DropdownFooter v-if="showFooter">
          <DropdownAction
            v-if="onDisconnect"
            color="error"
            :onClick="() => onDisconnect?.(item.id)"
          >
            <TrashIcon class="dropdown-action-icon" />
            {{ t('disconnect') }}
          </DropdownAction>

          <DestroyModal
            v-else-if="resourceApi.destroy"
            v-slot="{ openModal }"
            :subject="subject"
            :item="item"
            :resourceApi="resourceApi"
            :invalidateKeys="deleteInvalidateKeys"
            :resourceTranslation="resourceTranslation"
          >
            <DropdownAction color="error" :onClick="openModal">
              <TrashIcon class="dropdown-action-icon" />
              {{ t('destroy') }}
            </DropdownAction>
          </DestroyModal>
        </DropdownFooter>
      </template>

      <template v-else>
        <DropdownBody v-if="hasPermission(`${permissionBase}.destroy`)">
          <DropdownAction :onClick="handleRestore">
            <ArrowPathIcon class="dropdown-action-icon" />
            {{ t('restore') }}
          </DropdownAction>
        </DropdownBody>
      </template>
    </template>
  </Dropdown>
</template>
